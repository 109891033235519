<template>
  <div>
    <h4 class="d-block text-center mb-3 text-muted">LOG IN TO HiiKER</h4>
    <template v-if="onBoardingIncludesGoogle">
      <button @click="signInWithGoogle"
              class="my-2 d-block text-uppercase btn btn-google btn-block d-flex align-items-center justify-content-center">
        <img class="firebaseui-idp-icon mr-2" alt=""
             src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg">
        Login in With Google
      </button>
    </template>
    <template v-if="onBoardingIncludesApple">
      <button @click="signInWithApple"
              class="my-2 d-block text-uppercase btn btn-apple btn-block d-flex align-items-center justify-content-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-apple mr-2"
             viewBox="0 0 16 16">
          <path
            d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
          <path
            d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
        </svg>
        Login in With Apple
      </button>
    </template>
    <template v-if="onBoardingIncludesEmail">
      <template v-if="!onBoardingIsEmailOnly">
        <p class="row text-center d-block text-muted"> <strong>- OR -</strong></p>
      </template>
      <button @click="signInWithEmail"
              class="my-2 d-block text-uppercase btn btn-warning btn-block d-flex align-items-center justify-content-center">
        <span class="material-icons mr-2">mail</span>
        Login in With Email
      </button>
    </template>

    <p class="py-2 text-center">Don't have an account? <a href="#" @click="dontHaveAccount"><strong>Sign Up</strong></a>
    </p>

    <hr class="my-3">

    <small class="text-center d-block">By continuing, you are indicating that you accept our <a
        href="/terms-of-service"><u>Terms of Service</u></a> and <a href="/privacy"><u>Privacy Policy</u></a>.</small>
  </div>
</template>

<script lang="ts">
import ahoy from 'ahoy.js';
import { defineComponent } from 'vue';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { OnBoardingPage } from '../../utils/models/OnBoardingPage';
import { SignInProvider } from '../../utils/models/SignInProvider';
import { AuthMethods } from '../store/modules/OnBoardingModule';

export default defineComponent({
  methods: {
    signInWithGoogle() {
      this.$store.dispatch('onBoarding/signInWith', SignInProvider.Google);
    },
    signInWithFacebook() {
      this.$store.dispatch('onBoarding/signInWith', SignInProvider.Facebook);
    },
    signInWithApple() {
      this.$store.dispatch('onBoarding/signInWith', SignInProvider.Apple);
    },
    signInWithEmail() {
      // this.$store.dispatch('onBoarding/signInWith', SignInProvider.Email);
      this.$store.commit('onBoarding/pushPage', OnBoardingPage.EmailLogin);
    },
    dontHaveAccount() {
      this.$store.commit('onBoarding/setPageStack', [OnBoardingPage.SignUpOptions]);
    },
    mounted() {
      ahoy.track('onboarding_login_page_mounted');
    },
  },
  computed: {
    onBoardingIncludesEmail() {
      return this.$store.state.onBoarding.authMethods.includes(AuthMethods.Email);
    },
    onBoardingIncludesGoogle() {
      return this.$store.state.onBoarding.authMethods.includes(AuthMethods.Google);
    },
    onBoardingIncludesApple() {
      return this.$store.state.onBoarding.authMethods.includes(AuthMethods.Apple);
    },
    onBoardingIsEmailOnly() {
      return this.$store.state.onBoarding.authMethods.length === 1 && this.$store.state.onBoarding.authMethods.includes(AuthMethods.Email);
    },
  },
});
</script>

<style scoped>
.btn {
  font-size: 1em;
}
</style>
